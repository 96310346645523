import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

import { ROUTES } from 'shared/constants';

import NotFound from 'pages/notFound';
import Home from 'pages/home';

import Layout from 'components/Layout';
import BlankLayout from 'components/Layout/blank';

/**
 * Application routes
 * @return {JSX.Element}
 */
export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path={ROUTES.ROOT} element={<Layout />}>
        <Route path={ROUTES.ROOT} element={<Home />} />
      </Route>
      <Route path={ROUTES.ROOT} element={<BlankLayout />}>
        <Route path='*' element={<NotFound />} />
      </Route>
    </Switch>
  );
}
