import { initIntl } from './locales';

export const WHATSAPP_LINK = 'https://wa.me/5581991257788';
export const TRIPADVISOR_LINK =
'https://www.tripadvisor.com.br/Restaurant_Review-g304560-d2248105-Reviews-Chica_Pitanga-Recife_State_of_Pernambuco.html';
export const INSTAGRAM_LINK = 'https://www.instagram.com/chicapitangarecife/';

export const UTILS = {
  BLANK: '',
  SPACE: ' ',
  ELLIPSIS: '...',
  DOT: '.',
  QUESTION: '?',
  EQUAL: '=',
};

initIntl();

export const ROUTES = {
  ROOT: '/',
};
