import { createTheme } from '@mui/material/styles';

import { getThemeLocale } from 'shared/locales';

import CabinetGroteskM from 'assets/fonts/CabinetGrotesk-Medium.ttf';
import CabinetGroteskR from 'assets/fonts/CabinetGrotesk-Regular.ttf';

import '@fontsource/figtree/400.css';
import '@fontsource/figtree/600.css';


declare module '@mui/material/styles/createTheme' {
  interface Theme {
    fonts: {
      primary: string,
      secondary: string,
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    fonts?: {
      primary: string,
      secondary: string,
    };
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    logo: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    logo?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    logo: true;
  }
}

const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Figtree, Cabinet Grotesk',
    h1: {
      fontSize: 18,
      fontWeight: 700,
    },
    h2: {
      fontSize: 16,
      fontWeight: 500,
    },
    h3: {
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontSize: 10,
      fontWeight: 600,
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
      color: '#4D201A',
      fontFamily: 'Figtree',
      lineHeight: '32px',
    },
    caption: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#dccbb5',
      light: '#799fb8',
    },
    secondary: {
      main: '#dccbb5',
      light: '#6200EE',
    },
    text: {
      primary: '#5F6368',
      secondary: '#777d85',
    },
    error: {
      main: '#B30000',
    },
    success: {
      main: '#408000',
    },
    warning: {
      main: '#FF7F00',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1224,
      xl: 1800,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Cabinet Grotesk';
          font-style: regular;
          font-display: swap;
          font-weight: 400;
          src: local('CabinetGrotesk'), local('CabinetGrotesk-Regular'), url(${CabinetGroteskR}) format('ttf');
        }
      `,
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#dccbb5',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#5F6368',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'color': '#FAF6F0',
          'backgroundColor': '#CD4D39',
          'padding': '12px 24px',
          'borderRadius': '4px',
          'fontSize': '18px',
          'fontWeight': 400,
          'fontFamily': 'Figtree',
          'textTransform': 'none',
          '&:hover': {
            backgroundColor: '#4D201A',
          },
        },
        textSecondary: {
          'backgroundColor': '#FFFFFF',
          'color': '#CD4D39',
          '&:hover': {
            backgroundColor: '#FAF6F0',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Figtree',
          fontSize: '18px',
          fontWeight: 400,
          color: '#4D201A',
          cursor: 'pointer',
        },
        underlineAlways: {
          textDecorationColor: 'inherit',
        },
      },
    },
  },
}, getThemeLocale());

export default defaultTheme;
