/* eslint-disable quote-props */
import React, { CSSProperties } from 'react';

import { Hidden, useMediaQuery, Box, Toolbar, SxProps, Theme, Container, Button, Link } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';

import { useNavigate } from 'react-router-dom';

import defaultTheme from 'shared/theme';
import { INSTAGRAM_LINK, ROUTES, WHATSAPP_LINK } from 'shared/constants';

import LogoHWeb from 'assets/image/logo-h-web.svg';

const imageStyle: CSSProperties = {
  cursor: 'pointer',
  height: '45px',
};

const appBarStyle = (smDown: boolean): SxProps<Theme> => ({
  height: '136px',
  backgroundColor: '#faf6f0',
  display: 'flex',
  justifyContent: 'center',
});

const toolBarStyle: (smDown: boolean) => SxProps<Theme> = (smDown: boolean) => ({
  display: 'flex',
  justifyContent: smDown ? 'center' : 'unset',
});

/**
 * Permanent Drawer component
 * @return {JSX.Element}
 */
export default function AppBar(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleOrderClick = () => window.open(WHATSAPP_LINK, '_blank');

  return (
    <MuiAppBar
      elevation={0}
      position="fixed"
      sx={appBarStyle(smDown)}
    >
      <Container maxWidth="lg">
        <Toolbar sx={toolBarStyle(smDown)} disableGutters>
          <img style={imageStyle} src={LogoHWeb} onClick={() => navigate(ROUTES.ROOT)} />
          <Hidden smDown>
            <Box flexGrow={1} />
            <Link href={INSTAGRAM_LINK} mr="40px" underline='hover' target='_blank'>Instagram</Link>
            <Button onClick={handleOrderClick} sx={{ fontSize: '12px', fontWeight: 600 }}>FAÇA SUA ENCOMENDA</Button>
          </Hidden>
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}
