import React, { useState } from 'react';
import { Box, Container, SxProps, Theme, useMediaQuery } from '@mui/material';
import jss from 'jss';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player/file';

import defaultTheme from 'shared/theme';

import ThumbImage from 'assets/image/video-thumb.png';
import VideoFile from 'assets/videos/video.mp4';
import { ReactComponent as PlayIcon } from 'assets/image/play.svg';

const boxStyle: (smDown: boolean) => SxProps<Theme> = (smDown: boolean) => ({
  backgroundColor: '#4D201A',
  height: smDown ? '174px': '580px',
  width: '100%',
  backgroundImage: `url(${ThumbImage})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

/**
 * Video component
 * @return {JSX.Element}
 */
export default function Video():JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  const [play, setPlay] = useState(false);

  const handleClick = () => setPlay(!play);

  const style = {
    iframe: {
      'width': '100%',
      'height': smDown ? '174px': '580px',
      'border-radius': smDown ? 'unset' : '16px',
    },
  };

  const sheet = jss.createStyleSheet(style);
  sheet.attach();

  return <Box sx={{ backgroundColor: '#4D201A', padding: smDown ? '48px 0px' : '56px 0px' }}>
    <Container>
      {/* {
        play ? <YouTube
          videoId='uwVRWltzH7c'
          iframeClassName={sheet.classes.iframe}
          opts={{
            playerVars: {
              autoplay: 1,
              rel: 0,
            },
          }}
        /> : <Box sx={boxStyle(smDown)} onClick={handleClick}>
          <PlayIcon />
        </Box>
      } */}
      {
        play ? <ReactPlayer
          url={VideoFile}
          width={style.iframe.width}
          height={style.iframe.height}
          style={{ backgroundColor: '#000000' }}
          playing={play}
          controls
        /> :
          <Box sx={{
            width: '100%',
            height: smDown ? '174px': '580px',
            borderRadius: smDown ? 'unset' : '16px',
          }}>
            <Box sx={boxStyle(smDown)}>
              <PlayIcon onClick={handleClick} color='inherit' style={{ fontSize: '50px' }} />
            </Box>
          </Box>
      }
    </Container>
  </Box>;
}
