import React from 'react';

import {
  Container,
  Grid,
  useMediaQuery,
} from '@mui/material';

import defaultTheme from 'shared/theme';

import Highlight from 'components/Highlight';
import Video from 'components/Video';
import About from 'components/About';
import Dishes from 'components/Dishes';
import Chef from 'components/Chef';
import Restaurant from 'components/Restaurant';
import Quality from 'components/Quality';
import Events from 'components/Events';
import Buffet from 'components/Buffet';
import Footer from 'components/Footer';

/**
 * Home component
 * @return {JSX.Element}
 */
function Home(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));

  return (
    <>
      <Highlight />
      <Video />
      <Container maxWidth="lg">
        <Grid container sx={{ marginTop: smDown ? '45px' : '160px' }}>
          <About />
          <Dishes />
          <Chef />
        </Grid>
      </Container>
      <Buffet />
      <Quality />
      <Events />
      <Container maxWidth="lg">
        <Footer />
      </Container>
    </>
  );
}

export default Home;
