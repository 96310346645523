import React from 'react';
import { Box, Grid, Hidden, Typography, useMediaQuery } from '@mui/material';

import Image from 'assets/image/Claudemir.jpg';
import defaultTheme from 'shared/theme';
import Slide from '../Slide';

const Text = () => (
  <>
    <Typography variant="body1" maxWidth="392px">
      Formado pelo curso de Cozinheiro Internacional do Senac Pernambuco, o chef
      é conhecido pelas pesquisas dos ingredientes brasileiros. Traz na bagagem
      o ofício de cozinheiro, tão inspirado por sua mãe, dona Anita, que foi
      cozinheira líder por 17 anos do histórico restaurante Leite.
    </Typography>
    <br />
    <Typography variant="body1" maxWidth="392px">
      Por 14 anos esteve à frente do recifense Wiella Bistrô. Em 2012, levou o
      título “Chef do Ano” pelo prêmio “Melhores do Ano de Pernambuco”, da
      revista especializada Prazeres da Mesa. É autor do livro “Sonhos e
      Sabores”, relevando a importância gastronômica de insumos nordestinos
      desprestigiados.
    </Typography>
    <br />
    <Typography variant="body1" maxWidth="392px">
      Ainda constam estágios no Emiliano, junto ao chef Russo, braço direito do
      francês Laurent Suaudeau; no D.O.M de Alex Atala, ambos em São Paulo, e na
      Casa Marcelo, uma estrela Michelin, em Santiago de Compostela, na Espanha.
    </Typography>
  </>
);

/**
 * Chef component
 * @return {JSX.Element}
 */
export default function Chef(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  return (
    <>
      <Hidden smDown>
        <Grid item sm={1} />
      </Hidden>
      <Grid item xs={12} sm={5} mt={smDown ? '69px' : '160px'}>
        <Box
          sx={{
            backgroundColor: '#F0E8DD',
            padding: '4px 16px',
            borderRadius: '100px',
            width: 'fit-content',
            mb: '24px',
          }}
        >
          <Typography fontWeight={600} color="#4D201A" fontSize="12px">
            CHEF
          </Typography>
        </Box>
        <Typography
          color="#4D201A"
          fontSize={smDown ? '40px' : '72px'}
          fontFamily={'Cabinet Grotesk'}
          marginBottom={smDown ? '32px' : '40px'}
          lineHeight="1"
        >
          Sobre Claudemir Barros
        </Typography>
        <Hidden mdUp>
          <Slide images={[Image]} />
        </Hidden>
        <Hidden smDown>
          <Text />
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={5} mt={smDown ? '57px' : '160px'}>
        <Hidden smDown>
          <Slide images={[Image]} />
        </Hidden>
        <Hidden mdUp>
          <Text />
        </Hidden>
      </Grid>
      <Hidden smDown>
        <Grid item sm={1} />
      </Hidden>
    </>
  );
}
