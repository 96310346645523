import React from 'react';
import {
  Container,
  Grid,
  Hidden,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import defaultTheme from 'shared/theme';
import Image from 'assets/image/buffet.jpg';
import ImageMobile from 'assets/image/buffet-mobile.png';

const boxStyle: (smDown: boolean) => SxProps<Theme> = (smDown: boolean) => ({
  height: '420px',
  backgroundImage: `url(${smDown ? ImageMobile : Image})`,
  backgroundSize: 'cover',
});

const HighlightText = (props: { smDown: boolean }) => (
  <Typography
    fontSize={'24px'}
    fontWeight={500}
    color={props.smDown ? '#4D201A' : '#FAF6F0'}
    fontFamily={'Cabinet Grotesk'}
    lineHeight="32px"
    mt={props.smDown ? '24px' : 'unset'}
  >
    Foi pelo estilo inédito de buffet no quilo que o Chica Pitanga conquistou
    pernambucanos e turistas
  </Typography>
);

const Texts = (props: { smDown: boolean }) => (
  <>
    <Typography color="#4D201A" fontSize="18px" fontWeight={400}>
      Desde a sua abertura, o restaurante preza, sobretudo, pela variedade e
      qualidade do cardápio – e criatividade. São oferecidas as melhores
      proteínas, ingredientes de alta qualidade. Há pratos quentes, saladas e
      sobremesas para todos os gostos. A única dificuldade do cliente é escolher
      o que colocar no prato porque a variedade é grande e o nosso tempero
      caprichado. Quem vai uma vez ao Chica, não esquece.
    </Typography>
    <br />
    <Typography
      color="#4D201A"
      fontSize="18px"
      fontWeight={400}
      mb={props.smDown ? '56px' : '80px'}
    >
      As sobremesas, aliás, são um capítulo à parte: há uma vitrine inteira
      repleta de tortas e bolos regionais capazes de enfraquecer qualquer dieta.
    </Typography>
  </>
);

const MdUp = (props: { smDown: boolean }) => (
  <Container>
    <Grid
      container
      sx={boxStyle(props.smDown)}
      mt={'160px'}
      alignItems="center"
    >
      <Grid item xs={1} />
      <Grid item xs={3} mt={'270px'}>
        <Typography
          fontSize={'72px'}
          color="#FAF6F0"
          fontFamily={'Cabinet Grotesk'}
          lineHeight="1"
        >
          Buffet
        </Typography>
      </Grid>
      <Grid item xs mt={'270px'}>
        <HighlightText smDown={props.smDown} />
      </Grid>
      <Grid item xs={1} />
    </Grid>
    <Grid container alignItems="center">
      <Grid item xs={1} />
      <Grid item xs={10} mt={'80px'}>
        <Texts smDown={props.smDown} />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  </Container>
);

/**
 * Buffet component
 * @return {JSX.Element}
 */
export default function Buffet(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));

  return (
    <>
      <Hidden smDown>
        <MdUp smDown={smDown} />
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          sx={boxStyle(smDown)}
          mt={smDown ? '41px' : '160px'}
          alignItems="center"
        >
          <Container>
            <Typography
              fontSize={'72px'}
              color="#FAF6F0"
              fontFamily={'Cabinet Grotesk'}
              lineHeight="1"
              mt="270px"
            >
              Buffet
            </Typography>
          </Container>
        </Grid>
        <Container>
          <HighlightText smDown={smDown} />
          <br />
          <Texts smDown={smDown} />
        </Container>
      </Hidden>
    </>
  );
}
