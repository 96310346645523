import React, { CSSProperties } from 'react';
import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './splide.css';
import defaultTheme from 'shared/theme';

const imageBoxStyle = (smDown: boolean, height?: string): SxProps<Theme> => ({
  maxWidth: '600px',
  width: '100%',
  height: height ?? (smDown ? '426px' : '698px'),
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  borderRadius: '4px',
  border: 'none',
});

const paginationStyle = (right: boolean): CSSProperties => ({
  position: 'absolute',
  left: right ? 'unset' : '-20px',
  width: 'fit-content',
  bottom: '-40px',
  right: right ? '-20px' : 'unset',
});

interface Props {
  right?: boolean
  height?: string
  hidePagination?: boolean
  images: string[]
}

/**
 * Slide component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Slide(props: Props): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  return <Splide
    id="highlights"
    aria-label="Destaques"
    options={{ rewind: true, pagination: !props.hidePagination, autoplay: true, arrows: false }}
    hasTrack={false}
  >
    <SplideTrack>
      {
        props.images.map((image, index) => <SplideSlide key={index}>
          <Box sx={{ ...imageBoxStyle(smDown, props.height), backgroundImage: `url(${image})` }} />
        </SplideSlide>)
      }
    </SplideTrack>
    <Box position="relative">
      <ul className="splide__pagination" style={paginationStyle(!!props.right)}></ul>
    </Box>
  </Splide>;
}
