import React from 'react';
import { Button, Grid, Hidden, Typography, useMediaQuery } from '@mui/material';

import defaultTheme from 'shared/theme';
import { WHATSAPP_LINK } from 'shared/constants';

/**
 * About component
 * @return {JSX.Element}
 */
export default function About(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));

  const handleOrderClick = () =>
    (window.open(WHATSAPP_LINK, '_blank'));

  return (
    <>
      <Hidden smDown>
        <Grid item sm={1} />
      </Hidden>
      <Grid item xs={12} sm={5}>
        <Typography
          color="#4D201A"
          fontSize={smDown ? '40px' : '72px'}
          fontFamily={'Cabinet Grotesk'}
          marginBottom={smDown ? '32px' : '40px'}
          lineHeight="1"
        >
          Referência em gastronomia brasileira
        </Typography>
        <Hidden smDown>
          <Button onClick={handleOrderClick}>Faça sua encomenda</Button>
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Typography variant="body1">
          Há quase 30 anos, nossa cozinha oferece uma culinária rica de
          influências. Não só de contexto histórico, formado por ingredientes
          europeus e brasileiros, mas também de bases afetivas, com receitas que
          preenchem a mesa da nossa família de origem portuguesa. De técnica
          apurada, o restaurante se tornou referência pela qualidade
          gastronômica oferecida aos pernambucanos e visitantes de todo o mundo.
        </Typography>
        <br />
        <Typography variant="body1">
          Para eles, um cardápio amplo e democrático, começando pelo premiado
          bufê de almoço, que entrega o mais alto padrão de insumos. Seja do
          autêntico sabor de preparos regionais ao criterioso prato
          internacional. Com esse equilíbrio consistente, chegamos ao menu à la
          carte do jantar, assinado pelo prestigiado chef Claudemir Barros, que
          dá seu toque de brasilidade às nossas referências lusas.
        </Typography>
        <br />
        <Typography variant="body1">
          Para completar, um salão confortável e contemporâneo, que exalta nossa
          trajetória através de uma pitangueira, convidando a todos para
          momentos leves, descontraídos e deliciosos ao redor da mesa.
        </Typography>
        <Hidden mdUp>
          <Button fullWidth sx={{ mt: '32px', mb: '52px' }}>
            Faça sua encomenda
          </Button>
        </Hidden>
      </Grid>
      <Hidden smDown>
        <Grid item sm={1} />
      </Hidden>
    </>
  );
}
