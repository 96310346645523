import React from 'react';
import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';

import PatternImage from 'assets/image/pattern.svg';
import defaultTheme from 'shared/theme';

const boxStyle: (smDown: boolean) => SxProps<Theme> = (smDown: boolean) => ({
  backgroundColor: '#CD4D39',
  height: smDown ? '192px': '336px',
  width: '100%',
  backgroundImage: `url(${PatternImage})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

/**
 * Highlight component
 * @return {JSX.Element}
 */
export default function Highlight(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  return (
    <Box sx={boxStyle(smDown)}>
      <Typography
        fontSize={smDown ? '40px' : '72px'}
        fontWeight={400}
        fontFamily={'Cabinet Grotesk'}
        color="#FFFFFF"
        align="center"
        lineHeight={smDown ? '48px' : '72px'}
      >
        Onde os sabores
        <br /> ganham <strong>vida.</strong>
      </Typography>
    </Box>
  );
}
