import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import Routes from 'routes';

import defaultTheme from 'shared/theme';
import { initIntl } from 'shared/locales';

import ToastContainer from 'components/Toast/container';

import 'assets/css/index.css';

/**
 * Application component
 * @return {JSX.Element}
 */
function App(): JSX.Element {
  useEffect(() => {
    initIntl();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
