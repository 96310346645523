import React, { CSSProperties } from 'react';
import {
  Grid,
  Link,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import defaultTheme from 'shared/theme';
import { INSTAGRAM_LINK, TRIPADVISOR_LINK, WHATSAPP_LINK } from 'shared/constants';
import InstagramIcon from 'assets/image/instagram.svg';
import TripAdvisorIcon from 'assets/image/tripadvisor.svg';
import LogoImage from 'assets/image/logo-v.svg';

const itemStyle: (smDown: boolean) => SxProps<Theme> = (smDown: boolean) => ({
  color: '#4D201A',
  fontSize: '18px',
  fontWeight: 400,
  textDecoration: 'none',
});

const titleStyle: (smDown: boolean) => SxProps<Theme> = (smDown: boolean) => ({
  color: '#4D201A',
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'Cabinet Grotesk',
  marginBottom: '24px',
});

/**
 * Footer component
 * @return {JSX.Element}
 */
export default function Footer(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));

  return (
    <Grid
      container
      rowSpacing={6}
      pt={smDown ? '64px' : '160px'}
      pb={smDown ? '53px' : '87px'}
    >
      <Grid item xs={12} sm={3}>
        <Grid container>
          <Grid item xs={12}>
            <img
              src={LogoImage}
              alt="Logo Chica Pitanga"
              style={{ marginBottom: '38px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={titleStyle(smDown)}>Visite</Typography>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center" mb="15px">
            <img
              src={InstagramIcon}
              alt="Instagram Icon"
              style={{ marginRight: '8px' }}
            />
            <Link href={INSTAGRAM_LINK} underline='none' target='_blank'>Instagram</Link>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center" mb="15px">
            <img
              src={TripAdvisorIcon}
              alt="TripAdvisor Icon"
              style={{ marginRight: '8px' }}
            />
            <Link
              href={TRIPADVISOR_LINK}
              underline='none'
              target='_blank'
            >
              TripAdvisor
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={titleStyle(smDown)}>Localização</Typography>
          </Grid>
          <Grid item xs={12} mb="15px">
            <Typography sx={itemStyle(smDown)}>
              Rua Petrolina, 19
              <br />
              Boa Viagem, Recife - PE
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link href='https://maps.app.goo.gl/SXS155qkrr628xeF9' underline="always" target='_blank'>Google maps</Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={titleStyle(smDown)}>Horário</Typography>
          </Grid>
          <Grid item xs={12} mb="15px">
            <Typography sx={itemStyle(smDown)}>
              Seg - Sex: 11h - 23h
              <br />
              Sáb e Dom: 11h - 22h
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link href={WHATSAPP_LINK} underline="always" target='_blank'>Faça sua encomenda</Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={titleStyle(smDown)}>Contato</Typography>
          </Grid>
          <Grid item xs={12} mb="15px">
            <Typography sx={itemStyle(smDown)}>
              Email
              <br />
              <a
                style={itemStyle(smDown) as CSSProperties}
                href='mailto:chicapitanga@chicapitanga.com.br'
              >
                chicapitanga@chicapitanga.com.br
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} mb="45px">
            <Typography sx={itemStyle(smDown)}>
              Telefone
              <br />
              (81) 3465-2224
            </Typography>
          </Grid>
          <Grid item xs={12} mb="40px">
            <Link href="mailto:chicapitanga@chicapitanga.com.br" underline="none" sx={titleStyle(smDown)}>Trabalhe Conosco</Link>
          </Grid>
          <Grid item xs={12}>
            <Link href="mailto:pedidos@chicapitanga.com.br" underline="none" sx={titleStyle(smDown)}>Fornecedores</Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
