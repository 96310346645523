import React from 'react';
import { Button, Container, Grid, Hidden, Typography, useMediaQuery } from '@mui/material';

import defaultTheme from 'shared/theme';
import { TRIPADVISOR_LINK } from 'shared/constants';
import TripadvisorImage from 'assets/image/quality-tripadvisor.png';

/**
 * Quality component
 * @return {JSX.Element}
 */
export default function Quality(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));

  const handleAvaliationsClick = () =>
    (window.open(TRIPADVISOR_LINK, '_blank'));

  return (
    <Grid
      sx={{ backgroundColor: '#F0E8DD' }}
    >
      <Container>
        <Grid
          container
          padding={smDown ? '56px 0px' : '106px 0px 143px 0px'}
          sx={{ backgroundColor: '#F0E8DD' }}
        >
          <Hidden smDown>
            <Grid item sm={1} />
          </Hidden>
          <Grid item xs={12} sm={3} mb={smDown ? '42px' : 'unset'}>
            <Typography
              color="#4D201A"
              fontSize={'40px'}
              fontFamily={'Cabinet Grotesk'}
              lineHeight="48px"
              mb="24px"
              fontWeight={400}
            >
            Qualidade e Responsabilidade social
            </Typography>
            <img width="288px" height="188px" src={TripadvisorImage} alt="Rectangle" />
          </Grid>
          <Hidden smDown>
            <Grid item sm={1} />
          </Hidden>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="#4D201A" maxWidth="600px" mb={smDown ? '24px' : '109px'}>
            Em dezembro de 2005, o Chica Pitanga completou uma década de
            serviços reconhecidos pela sociedade e mídia, obtendo, entre os anos
            de 2000 e 2003, o título de “O Melhor Self-Service”, pela revista
            VEJA. Em 2004 e 2005, para confirmar ainda mais o padrão
            internacional, o restaurante conquistou o selo ISO 9000/2000,
            através de Auditoria de Certificação e Manutenção.
            </Typography>
            <Button onClick={handleAvaliationsClick} fullWidth={smDown}>
            Ver avaliações
            </Button>
          </Grid>
          <Hidden smDown>
            <Grid item sm={1} />
          </Hidden>
        </Grid>
      </Container>
    </Grid>
  );
}
