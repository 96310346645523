import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Image1 from 'assets/image/event1.jpeg';
import Image2 from 'assets/image/event2.jpeg';
import Image3 from 'assets/image/event3.jpeg';
import Image4 from 'assets/image/event4.jpeg';
import Image5 from 'assets/image/event5.jpeg';
import Image6 from 'assets/image/event6.jpeg';
import Image7 from 'assets/image/event7.jpeg';
import Image8 from 'assets/image/event8.jpeg';
import Image9 from 'assets/image/event9.jpeg';
import Image10 from 'assets/image/event10.jpeg';
import Image11 from 'assets/image/event11.jpeg';
import Image12 from 'assets/image/event12.jpeg';
import Image13 from 'assets/image/event13.jpeg';

import Slide from '../Slide';
import defaultTheme from 'shared/theme';

const Title = () => (
  <Typography
    color="#FFF"
    fontFamily={'Cabinet Grotesk'}
    fontSize="40px"
    fontWeight={400}
    mr={'24px'}
    mb={'24px'}
  >
    Eventos
  </Typography>
);

const Text = (props: { mdUp: boolean }) => (
  <Box mr={'24px'} mt={!props.mdUp ? '20px' : 'unset'}>
    <Typography color="#FFF" variant="body1">
      Já fazendo parte do dia a dia dos pernambucanos desde 1995 como um dos
      melhores restaurantes do Recife, além dos seus serviços de almoço em
      estilo bufê e jantar à la carte com toques de brasilidades, o Chica
      Pitanga também disponibiliza o serviço de ‘Eventos’.
    </Typography>
    <br />
    <Typography
      color="#FFF"
      variant="body1"
      mb={'24px'}
    >
      Pensados sob medida para cada ocasião, os eventos - confrarias, mini
      weddings, aniversários, bodas, coquetéis, corporativos - contam com a
      qualidade da gastronomia executada pela nossa equipe (é possível definir
      menus especiais para ocasião), com a nossa cuidadosa hospitalidade e
      ambiente amplo e charmoso para qualquer tipo de celebração.
    </Typography>
  </Box>
);

const Images = (props: { mdUp: boolean }) => (
  <Slide
    hidePagination={!props.mdUp}
    height="400px"
    images={[
      Image1,
      Image2,
      Image3,
      Image4,
      Image5,
      Image6,
      Image7,
      Image8,
      Image9,
      Image10,
      Image11,
      Image12,
      Image13,
    ]}
  />
);

const MyButton = (props: { mdUp: boolean }) => (
  <Button fullWidth={!props.mdUp} color="secondary" onClick={() => window.location.href = 'mailto:chicapitanga@chicapitanga.com.br'}>
    Entrar em contato
  </Button>
);

const MdUp = (props: { mdUp: boolean }) => (
  <Container>
    <Grid container>
      <Grid item xs={6}>
        <Title />
        <Text mdUp={props.mdUp} />
      </Grid>
      <Grid item xs={6}>
        <Images mdUp={props.mdUp} />
      </Grid>
    </Grid>
    <MyButton mdUp={props.mdUp} />
  </Container>
);

const SmDown = (props: { mdUp: boolean }) => <>
  <Container>
    <Title />
  </Container>
  <Images mdUp={props.mdUp} />
  <Container>
    <Text mdUp={props.mdUp} />
    <MyButton mdUp={props.mdUp} />
  </Container>
</>;

/**
 * Events component
 * @return {JSX.Element}
 */
export default function Events(): JSX.Element {
  const mdUp = useMediaQuery(defaultTheme.breakpoints.up('md'));
  return (
    <Box
      sx={{
        backgroundColor: '#CD4D39',
        padding: mdUp ? '96px 108px' : '56px 0px',
      }}
    >
      <Hidden smDown>
        <MdUp mdUp={mdUp} />
      </Hidden>
      <Hidden mdUp>
        <SmDown mdUp={mdUp} />
      </Hidden>
    </Box>
  );
}
