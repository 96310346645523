import React, { CSSProperties } from 'react';

import { Box, Container, CssBaseline, Paper, SxProps, Theme, useMediaQuery } from '@mui/material';

import { Outlet } from 'react-router-dom';

import defaultTheme from 'shared/theme';
import AppBar from './AppBar';

/**
 * Default layout
 * @return {JSX.Element}
 */
function Layout(): JSX.Element {
  return (
    <>
      <CssBaseline />
      <AppBar />
      <Box width="100%" mt="136px" sx={{ backgroundColor: '#faf6f0' }}>
        <Outlet />
      </Box>
    </>
  );
};

export default Layout;
