import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import Slide from 'components/Slide';
import defaultTheme from 'shared/theme';

import Image1 from 'assets/image/1.jpg';
import Image2 from 'assets/image/2.jpg';
import Image3 from 'assets/image/3.jpg';
import Image4 from 'assets/image/4.jpg';
import Image5 from 'assets/image/5.jpg';
import Image6 from 'assets/image/6.jpg';
import Image7 from 'assets/image/7.jpg';
import Image8 from 'assets/image/8.jpg';
import Image9 from 'assets/image/9.jpg';
import Image10 from 'assets/image/10.jpg';
import Image11 from 'assets/image/11.jpg';
import Image12 from 'assets/image/12.jpg';
import ImageSpace1 from 'assets/image/espaco1.jpg';
import ImageSpace2 from 'assets/image/espaco2.jpg';
import ImageSpace3 from 'assets/image/espaco3.jpg';
import ImageSpace4 from 'assets/image/espaco4.jpg';
import ImageSpace5 from 'assets/image/espaco5.jpg';
import ImageSpace6 from 'assets/image/espaco6.jpg';

/**
 * Dishes component
 * @return {JSX.Element}
 */
export default function Dishes(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  return (
    <Grid container columnSpacing={3} sx={{ mt: smDown ? 'unset' : '136px' }}>
      <Grid item xs={12} sm={6}>
        <Slide
          images={[
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
            Image7,
            Image8,
            Image9,
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mt: smDown ? '102px' : '120px' }}>
        <Slide
          images={[
            Image10,
            Image11,
            Image12,
            ImageSpace1,
            ImageSpace2,
            ImageSpace3,
            ImageSpace4,
            ImageSpace5,
            ImageSpace6,
          ]}
          right={!smDown}
        />
      </Grid>
    </Grid>
  );
}
